/* popup.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-modal {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
    padding: 20px;
}

.popup-content {
    margin-bottom: 20px;
}
.content-wrapper{
    color: #538ec4;
}
.popup-content p {
    font-family: var(--font-family);
    font-size: 16px;
}

.popup-content button {
    font-family: var(--font-family);
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup-content button:hover {
    background-color: #0056b3;
}
